var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-h4 mb-2"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('v-row',{staticClass:"align-center mb-5"},[_vm._l((_vm.filter),function(item,index){return [_c('v-col',{key:("filter-item-" + index),attrs:{"cols":"3"}},[[_c('v-select',{attrs:{"items":item.list,"item-text":"name","item-value":"id","label":item.name,"clearable":""},on:{"change":function($event){return _vm.changeFilter()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]],2)]}),_c('v-col',{attrs:{"cols":"auto ms-auto"}},[(_vm.$store.getters.isAdmin)?_c('v-btn',{attrs:{"color":"teal","dark":"","to":"/users/add"}},[_vm._v(_vm._s(_vm.$t('btn.add')))]):_vm._e()],1)],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"item.GROUP_ID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.find = _vm.userGroups.find(function (group) { return group.id === item.GROUP_ID; })) ? _vm.find.name : '-')+" ")]}},{key:"item.ACTIVE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.ACTIVE === 'Y') ? _vm.$t('yes') : _vm.$t('no'))+" ")]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.isAdmin)?_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":"","color":"teal"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/users/" + (item.ID))}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('btn.detail'))+" ")])],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }